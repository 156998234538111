import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import Login from "./pages/Login";
import Navbar from "./components/layout/Navbar";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import PodSetup from "./pages/PodSetup";
import Analytics from "./pages/Analytics";
import Signup from "./pages/Signup";
import InactivityHandler from './components/auth/InactivityHandler';
import Help from "./pages/Help";
import VideoGeneration from "./pages/VideoGeneration.tsx";

const ProtectedRoute = ({ children }) => {
  const authToken = localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token');

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  // Check if token is expired
  const token = JSON.parse(authToken);
  const expiresAt = new Date(token.expires_at * 1000);
  const currentTime = new Date();

  if (currentTime >= expiresAt) {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }

  return <InactivityHandler>{children}</InactivityHandler>;
};

const PublicRoute = ({ children }) => {
  const authToken = localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token');

  if (authToken) {
    const token = JSON.parse(authToken);
    const expiresAt = new Date(token.expires_at * 1000);
    const currentTime = new Date();

    if (currentTime < expiresAt) {
      return <Navigate to="/home" replace />;
    }
    localStorage.clear();
  }

  return children;
};

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              }
            />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pod-setup"
              element={
                <ProtectedRoute>
                  <PodSetup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/video-generation"
              element={
                <ProtectedRoute>
                  <VideoGeneration />
                </ProtectedRoute>
              }
            />
            <Route
              path="/help"
              element={
                <ProtectedRoute>
                  <Help />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to="/login" replace />}
            />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default function AppWithTheme() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
}
