import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  AlertTitle,
  Chip,
} from '@mui/material';
import { PlayArrow, Stop, Refresh, AccessTime, CheckCircle } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import InitialLoadingOverlay from '../components/InitialLoadingOverlay';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const RUNPOD_API_URL = process.env.REACT_APP_BACKEND_URL;

const ErrorDialog = ({ open, handleClose, errorMessage, onDelete }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="error-dialog-title"
  >
    <DialogTitle id="error-dialog-title" sx={{ color: 'error.main' }}>
      Pod Start Error
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {errorMessage}
        <br /><br />
        Please delete this pod and create a new one.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="inherit">
        Cancel
      </Button>
      <Button onClick={onDelete} variant="contained" color="error">
        Delete Pod
      </Button>
    </DialogActions>
  </Dialog>
);

const Dashboard = () => {
  const [pods, setPods] = useState([]);
  const [selectedPod, setSelectedPod] = useState('');
  const [podStatus, setPodStatus] = useState(null);
  const [backendStatus, setBackendStatus] = useState('checking');
  const [showStartMessage, setShowStartMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const authToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
  const accessToken = authToken?.access_token;

  console.log("localhost ~", localStorage);

  useEffect(() => {
    fetchPods();
  }, []);

  useEffect(() => {
    if (podStatus?.status === 'STARTING') {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 2;
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [podStatus?.status]);

  const fetchPods = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${RUNPOD_API_URL}/pods`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setPods(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (err) {
      setError('Failed to fetch pods');
      console.error(err);
      setPods([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePodSelect = async (podId) => {
    setSelectedPod(podId);
    if (podId) {
      await checkPodStatus(podId);
    }
  };

  const logPodActivity = async (podId, actionType, podDetails) => {
    try {
      // First create user profile if it doesn't exist
      const { error: profileError } = await supabase
        .from('user_profiles')
        .upsert({
          id: userInfo.id,
          full_name: userInfo.full_name,
          email: userInfo.email,
          total_pod_starts: 0,
          total_pod_stops: 0
        });

      if (profileError) throw profileError;

      // Log pod activity
      await supabase.from('pod_activities').insert([{
        user_id: userInfo.id,
        pod_id: podId,
        pod_name: podDetails?.name || podId,
        action_type: actionType,
        pod_status: podDetails?.status,
        pod_type: podDetails?.runtime?.name,
        gpu_count: podDetails?.gpuCount || 0
      }]);

      // Update user profile statistics
      const field = actionType === 'START' ? 'total_pod_starts' : 'total_pod_stops';
      await supabase.from('user_profiles')
        .update({ [field]: supabase.raw(`${field} + 1`) })
        .eq('id', userInfo.id);

    } catch (error) {
      console.error('Failed to log activity:', error);
    }
  };

  const handleStartPod = async (selectedPod) => {
    if (!selectedPod) return;
    try {
      setLoading(true);
      const response = await axios.post(`${RUNPOD_API_URL}/pods/start?pod_id=${selectedPod}`, null, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      const podDetails = await checkPodStatus(selectedPod);
      await logPodActivity(selectedPod, 'START', podDetails);
      
      setOpenDialog(true);
      await fetchPods();
      setError(null);
    } catch (err) {
      console.error(err);
      setErrorMessage(err.response?.data?.detail || 'Failed to start pod. Please delete this pod and create a new one.');
      setOpenErrorDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleStopPod = async () => {
    if (!selectedPod) return;
    try {
      setLoading(true);
      await axios.post(`${RUNPOD_API_URL}/pods/stop?pod_id=${selectedPod}`, null, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      
      const podDetails = await checkPodStatus(selectedPod);
      await logPodActivity(selectedPod, 'STOP', podDetails);
      
      await fetchPods();
      setError(null);
    } catch (err) {
      setError('Failed to stop pod');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const checkPodStatus = async (podId) => {
    try {
      const response = await axios.get(`${RUNPOD_API_URL}/pods/${podId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setPodStatus(response.data);
      return response.data;
    } catch (err) {
      console.error('Failed to fetch pod status:', err);
      return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'running':
        return 'success.main';
      case 'stopped':
        return 'error.main';
      case 'starting':
        return 'warning.main';
      default:
        return 'text.secondary';
    }
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  const handlePodDelete = async (podId) => {
    if (!podId) return;
    try {
      const response = await axios.delete(`${RUNPOD_API_URL}/pods/delete?pod_id=${podId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      await supabase.from('pod_activities').insert([{
        user_id: userInfo.id,
        pod_id: podId,
        action_type: 'DELETE',
        pod_status: 'DELETED'
      }]);

      fetchPods();
      setOpenErrorDialog(false);
    } catch (err) {
      console.error(err);
      setError('Failed to delete pod');
    }
  };

  const handleDeleteFromError = async () => {
    try {
      await handlePodDelete(selectedPod);
      setOpenErrorDialog(false);
      navigate('/pod-setup');
    } catch (err) {
      console.error('Failed to delete pod:', err);
      setErrorMessage('Failed to delete pod. Please try again.');
    }
  };

  return (
    <>
      <InitialLoadingOverlay />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 2px 16px rgba(0,0,0,0.08)',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h5" component="h1" sx={{ fontWeight: 600 }}>
                  Pod Management
                </Typography>
                <Button
                  startIcon={<Refresh />}
                  onClick={fetchPods}
                  disabled={loading}
                >
                  Refresh Pods
                </Button>
              </Box>

              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Select Pod</InputLabel>
                <Select
                  value={selectedPod}
                  label="Select Pod"
                  onChange={(e) => handlePodSelect(e.target.value)}
                  disabled={loading}
                >
                  {pods.map((pod) => (
                    <MenuItem key={pod.id} value={pod.id}>
                      {pod.name || pod.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  mb: 3,
                  borderRadius: 3,
                  bgcolor: 'background.paper',
                  boxShadow: '0 4px 24px rgba(0,0,0,0.06)',
                  border: '1px solid',
                  borderColor: theme => 
                    podStatus?.status === 'RUNNING' ? theme.palette.success.light : 
                    podStatus?.status === 'EXITED' ? theme.palette.error.light : 
                    theme.palette.warning.light,
                  transition: 'all 0.3s ease'
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: podStatus?.status === 'RUNNING' ? '#4CAF50' : '#f44336',
                          boxShadow: `0 0 8px ${podStatus?.status === 'RUNNING' ? '#4CAF50' : '#f44336'}`,
                        }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {podStatus?.name || 'Unknown Pod'}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Paper
                        variant="outlined"
                        sx={{ p: 2, borderRadius: 2, bgcolor: 'background.default' }}
                      >
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Pod Details
                        </Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 2, alignItems: 'center' }}>
                          <Typography variant="body2" color="text.secondary">ID:</Typography>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>{podStatus?.id}</Typography>
                          
                          <Typography variant="body2" color="text.secondary">Status:</Typography>
                          <Chip
                            size="small"
                            label={podStatus?.status}
                            color={podStatus?.status === 'RUNNING' ? 'success' : 'error'}
                            sx={{ width: 'fit-content' }}
                          />
                          
                          <Typography variant="body2" color="text.secondary">GPU Count:</Typography>
                          <Typography variant="body2">{podStatus?.gpu_count}</Typography>
                          
                          <Typography variant="body2" color="text.secondary">Machine ID:</Typography>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>{podStatus?.machine_id}</Typography>
                        </Box>
                      </Paper>

                      <Paper
                        variant="outlined"
                        sx={{ p: 2, borderRadius: 2, bgcolor: 'background.default' }}
                      >
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Runtime Configuration
                        </Typography>
                        <Box sx={{ 
                          mt: 1,
                          p: 2,
                          borderRadius: 1,
                          bgcolor: 'grey.50',
                          fontFamily: 'monospace',
                          fontSize: '0.875rem',
                          overflow: 'auto',
                          maxHeight: 120
                        }}>
                          <pre style={{ margin: 0 }}>
                            {(() => {
                              try {
                                const runtimeData = typeof podStatus?.runtime === 'string' 
                                  ? JSON.parse(podStatus?.runtime) 
                                  : podStatus?.runtime || {};
                                return JSON.stringify(runtimeData, null, 2);
                              } catch (error) {
                                return '{}';
                              }
                            })()}
                          </pre>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {podStatus?.status === 'STARTING' && (
                      <Box sx={{ mt: 2 }}>
                        <LinearProgress 
                          variant="determinate"
                          value={progress}
                          sx={{ 
                            height: 8,
                            borderRadius: 4,
                            mb: 2,
                            bgcolor: 'rgba(255, 152, 0, 0.1)',
                            '& .MuiLinearProgress-bar': {
                              bgcolor: 'warning.main',
                            }
                          }} 
                        />
                        <Alert 
                          severity="info" 
                          icon={<AccessTime sx={{ fontSize: 24 }} />}
                          sx={{ borderRadius: 2 }}
                        >
                          <AlertTitle sx={{ fontWeight: 600 }}>Initializing Pod</AlertTitle>
                          Please wait while we set up your environment...
                        </Alert>
                      </Box>
                    )}

                    {podStatus?.status === 'RUNNING' && (
                      <Alert
                        severity="success"
                        icon={<CheckCircle sx={{ fontSize: 24 }} />}
                        sx={{ borderRadius: 2 }}
                      >
                        <AlertTitle sx={{ fontWeight: 600 }}>Pod is Active</AlertTitle>
                        Your environment is ready to use. You can now start generating images.
                      </Alert>
                    )}

                    <Box sx={{ mt: 'auto', display: 'flex', gap: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleNavigateHome}
                        sx={{ 
                          py: 1.5,
                          boxShadow: '0 2px 8px rgba(37, 99, 235, 0.2)',
                          '&:hover': {
                            boxShadow: '0 4px 12px rgba(37, 99, 235, 0.3)',
                          }
                        }}
                      >
                        Go to Home
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              <Box sx={{ display: 'flex', gap: 2 }}>
                {podStatus?.status !== 'RUNNING' && (
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<PlayArrow />}
                    onClick={() => handleStartPod(selectedPod)}
                    disabled={loading || !selectedPod}
                  >
                    Start Pod
                  </Button>
                )}
                {podStatus?.status === 'RUNNING' && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Stop />}
                    onClick={handleStopPod}
                    disabled={loading || !selectedPod}
                  >
                    Stop Pod
                  </Button>
                )}
              </Box>

              <Snackbar
                open={showStartMessage}
                autoHideDuration={6000}
                onClose={() => setShowStartMessage(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert onClose={() => setShowStartMessage(false)} severity="info" sx={{ width: '100%' }}>
                  Please wait 3-4 minutes for the server to be ready. You can return to the home page.
                </Alert>
              </Snackbar>

              {loading && (
                <Box sx={{ 
                  position: 'relative',
                  minHeight: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 4
                }}>
                  <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{
                      color: 'primary.main',
                      position: 'absolute',
                      zIndex: 1,
                    }}
                  />
                  <Box sx={{ 
                    mt: 8,
                    textAlign: 'center',
                    maxWidth: 400
                  }}>
                    <Typography variant="h6" gutterBottom color="primary.main">
                      Starting Backend Services
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      This process typically takes around 50 seconds. We're preparing everything for you...
                    </Typography>
                    <LinearProgress 
                      sx={{ 
                        mt: 3,
                        mb: 2,
                        height: 8,
                        borderRadius: 4,
                      }} 
                    />
                    <Typography variant="caption" color="text.secondary">
                      Please don't refresh the page
                    </Typography>
                  </Box>
                </Box>
              )}

             
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            Pod Started Successfully
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <Typography variant="body1">
              The pod is starting up. It may take 3-4 minutes for the server to be fully ready.
              Would you like to return to the home page?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button onClick={() => setOpenDialog(false)} color="inherit">
              Stay Here
            </Button>
            <Button 
              onClick={handleNavigateHome} 
              variant="contained" 
              color="primary"
              autoFocus
            >
              Go to Home
            </Button>
          </DialogActions>
        </Dialog>

        <ErrorDialog
          open={openErrorDialog}
          handleClose={() => setOpenErrorDialog(false)}
          errorMessage={errorMessage}
          onDelete={handleDeleteFromError}
        />
      </Container>
    </>
  );
};

export default Dashboard; 