import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Tooltip,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from '../../supabaseClient/supabase';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { useTheme } from '../../context/ThemeContext';
import { format } from 'date-fns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CodeIcon from '@mui/icons-material/Code';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    setLogoutDialog(true);
  };

  const handleLogoutCancel = () => {
    setLogoutDialog(false);
  };

  const handleLogoutConfirm = async () => {
    try {
      setLoggingOut(true);
      const { error } = await signOut();
      if (!error) {
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoggingOut(false);
      setLogoutDialog(false);
    }
  };

  const isActive = (path) => location.pathname === path;

  const navItems = [
    { path: '/home', label: 'Image Generator', icon: <ImageIcon /> },
    { path: '/dashboard', label: 'Pod Management', icon: <DashboardIcon /> },
    { path: '/pod-setup', label: 'Pod Setup', icon: <SettingsIcon /> },
    { path: '/analytics', label: 'Analytics', icon: <BarChartIcon /> },
    { path: '/video-generation', label: 'Video Generation', icon: <VideoLibraryIcon /> },
    { path: '/help', label: 'Help', icon: <HelpOutlineIcon /> },
  ];

  const handleHelpClick = () => {
    navigate('/help');
  };

  return (
    <>
      <AppBar 
        position="fixed"
        elevation={1}
        sx={{
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar 
            disableGutters 
            sx={{ 
              minHeight: '64px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  mr: 4,
                }}
              >
                EasyQuickAI
              </Typography>
            </Box>

            {!user ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/login')}
                startIcon={<AccountCircleIcon />}
              >
                Login
              </Button>
            ) : (
              <>
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    gap: 0.5
                  }}
                >
                  {navItems.map((item) => (
                    <Button
                      key={item.path}
                      variant={isActive(item.path) ? "contained" : "text"}
                      color="primary"
                      onClick={() => navigate(item.path)}
                      startIcon={item.icon}
                      size="small"
                      sx={{
                        px: 1.5,
                        py: 0.75,
                        minWidth: 'auto',
                        textTransform: 'none',
                        borderRadius: 1,
                        fontSize: '0.875rem',
                        ...(isActive(item.path) ? {
                          backgroundColor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'primary.dark',
                          },
                        } : {
                          color: 'text.primary',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          },
                        }),
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 1,
                  ml: 2
                }}>
                  <Tooltip title="Toggle theme">
                    <IconButton 
                      onClick={toggleTheme} 
                      color="primary" 
                      size="small"
                      sx={{ 
                        bgcolor: 'action.hover',
                        '&:hover': { bgcolor: 'action.selected' }
                      }}
                    >
                      {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Notifications">
                    <IconButton 
                      size="small"
                      sx={{ 
                        bgcolor: 'action.hover',
                        '&:hover': { bgcolor: 'action.selected' }
                      }}
                    >
                      <Badge badgeContent={4} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  <Divider orientation="vertical" flexItem />

                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleMenuOpen}
                      size="small"
                      sx={{ 
                        ml: 1,
                        border: '2px solid',
                        borderColor: 'primary.main',
                        '&:hover': { bgcolor: 'action.hover' }
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          bgcolor: 'primary.main',
                          fontSize: '0.875rem',
                          fontWeight: 600
                        }}
                      >
                        {userInfo?.full_name?.charAt(0) || 'U'}
                      </Avatar>
                    </IconButton>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    PaperProps={{
                      elevation: 2,
                      sx: {
                        mt: 1.5,
                        minWidth: 220,
                        borderRadius: 1,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                  >
                    <Box sx={{ px: 2, py: 1.5 }}>
                      <Typography variant="subtitle2" color="text.primary" fontWeight={600}>
                        {userInfo?.full_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-all' }}>
                        {userInfo?.email}
                      </Typography>
                    </Box>
                    <Divider />
                    <MenuItem onClick={handleMenuClose} sx={{ py: 1.5 }}>
                      <AccountCircleIcon sx={{ mr: 2, fontSize: 20 }} /> Profile
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose} sx={{ py: 1.5 }}>
                      <SettingsIcon sx={{ mr: 2, fontSize: 20 }} /> Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem 
                      onClick={handleLogoutClick} 
                      sx={{ 
                        color: 'error.main',
                        py: 1.5
                      }}
                    >
                      <LogoutIcon sx={{ mr: 2, fontSize: 20 }} /> Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      
      <Toolbar />

      <Box
        component="footer"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '1px solid',
          borderColor: 'divider',
          bgcolor: theme => theme.palette.background.paper,
          backdropFilter: 'blur(12px)',
          boxShadow: '0 -4px 16px rgba(0,0,0,0.08)',
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: 1.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                px: 2,
                py: 0.5,
                borderRadius: 2,
                bgcolor: 'rgba(0,0,0,0.02)',
                border: '1px solid',
                borderColor: 'divider',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,0.04)',
                  transform: 'translateY(-1px)',
                }
              }}
            >
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary',
                  fontWeight: 500,
                  letterSpacing: '0.02em',
                }}
              >
                © 2025 All Rights Reserved
              </Typography>

              <Divider orientation="vertical" flexItem />

              <Typography 
                variant="body2" 
                sx={{ 
                  fontWeight: 500,
                  background: 'linear-gradient(45deg, #2563eb, #1d4ed8)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}
              >
                Developed by{' '}
                <a 
                  href="https://ishwor-subedi.com.np" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ 
                    textDecoration: 'none',
                    background: 'linear-gradient(45deg, #2563eb, #1d4ed8)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontWeight: 600,
                  }}
                >
                  Ishwor Subedi
                </a>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ height: '52px' }} />

      <Dialog
        open={logoutDialog}
        onClose={handleLogoutCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ 
          pb: 1,
          background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
          color: 'white'
        }}>
          Confirm Logout
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography>
            Are you sure you want to log out? Any unsaved changes will be lost.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={handleLogoutCancel} 
            disabled={loggingOut}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            color="error"
            variant="contained"
            disabled={loggingOut}
            startIcon={loggingOut ? <CircularProgress size={20} /> : <LogoutIcon />}
          >
            {loggingOut ? 'Logging out...' : 'Logout'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;