import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Divider,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import UploadIcon from '@mui/icons-material/Upload';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import supabase from "../supabaseClient/supabase";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const RUNPOD_API_URL = process.env.REACT_APP_BACKEND_URL;

type PrincessEntry = {
  id: string;
  production_status: string;
  publish_status: string;
  title: string;
  caption: string;
  image_url: string;
  prompt_a: string;
  action_a: string;
  video_a: string;
};

interface FormData {
  id: string;
  production_status: string;
  publish_status: string;
  title: string;
  caption: string;
  prompt_a: string;
  image_url: string;
  action_a: string;
  video_a: string;
}

// Helper function to get next ID
const getNextId = (data: any[][]): number => {
  if (!data || data.length <= 1) return 1;
  const ids = data.slice(1).map(row => parseInt(row[0] || '0'));
  return Math.max(...ids, 0) + 1;
};

// Function to transform raw data into structured format
const formatPrincessData = (rawData: any[][]): PrincessEntry[] => {
  if (!rawData || rawData.length <= 1) return [];
  return rawData.slice(1).map(row => ({
    id: row[0] || '',
    production_status: row[1] || 'todo',
    publish_status: row[2] || 'todo',
    title: row[3] || '',
    caption: row[4] || '',
    image_url: row[5] || '',
    prompt_a: row[6] || '',
    action_a: row[7] || '',
    video_a: row[8] || ''
  }));
};

const initialFormState: FormData = {
  id: '1',
  production_status: 'todo',
  publish_status: 'todo',
  title: '',
  caption: '',
  prompt_a: '',
  image_url: '',
  action_a: '',
  video_a: ''
};

const VideoGeneration: React.FC = () => {
  const [spreadsheetData, setSpreadsheetData] = useState<PrincessEntry[]>([]);
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [error, setError] = useState<string>('');
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [generating, setGenerating] = useState(false);

  const fetchSpreadsheetData = async () => {
    try {
      setError('');
      const currentToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token') || '{}');
      if (!currentToken?.access_token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(`${RUNPOD_API_URL}/video/read_spreadsheet`, {
        headers: {
          'Authorization': `Bearer ${currentToken.access_token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      const formattedData = formatPrincessData(response.data);
      setSpreadsheetData(formattedData);
      
      // Set the next ID in the form
      const nextId = getNextId(response.data).toString();
      setFormData(prev => ({ ...prev, id: nextId }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred while fetching data';
      setError(errorMessage);
      console.error('Error fetching spreadsheet data:', error);
    }
  };

  useEffect(() => {
    fetchSpreadsheetData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      setError('');
      
      const file = event.target.files?.[0];
      if (!file) return;

      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      // Save the file for later use with the prompt generator
      setUploadedFile(file);

      // Create a unique file name with path
      const fileExt = file.name.split('.').pop();
      const fileName = `uploads/${Date.now()}.${fileExt}`;

      // Check auth session
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Authentication required');
      }

      // Upload with specific options
      const { error: uploadError } = await supabase.storage
        .from('Images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        console.error('Upload error details:', uploadError);
        throw new Error(`Upload failed: ${uploadError.message}`);
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('Images')
        .getPublicUrl(fileName);

      console.log("Upload successful, URL:", publicUrl);

      setFormData(prev => ({
        ...prev,
        image_url: publicUrl
      }));
    } catch (error) {
      console.error('Upload error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Error uploading file';
      setError(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const generatePrompts = async () => {
    if (!uploadedFile) {
      setError('Please upload an image first');
      return;
    }

    try {
      setGenerating(true);
      setError('');
      
      const currentToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token') || '{}');
      if (!currentToken?.access_token) {
        throw new Error('No authentication token found');
      }

      const formData = new FormData();
      formData.append('image', uploadedFile);

      const response = await axios.post(
        `${RUNPOD_API_URL}/prompt_generator`, 
        formData, 
        {
          headers: {
            'Authorization': `Bearer ${currentToken.access_token}`,
            'Content-Type': 'multipart/form-data',
            'ngrok-skip-browser-warning': 'true'
          }
        }
      );

      const { title, caption, prompt_a, action_a } = response.data;
      
      setFormData(prev => ({
        ...prev,
        title: title || prev.title,
        caption: caption || prev.caption,
        prompt_a: prompt_a || prev.prompt_a,
        action_a: action_a || prev.action_a
      }));
      
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error generating prompts';
      setError(errorMessage);
      console.error('Error generating prompts:', error);
    } finally {
      setGenerating(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setError('');
      const currentToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token') || '{}');
      if (!currentToken?.access_token) {
        throw new Error('No authentication token found');
      }

      await axios.post(`${RUNPOD_API_URL}/video/add_row`, formData, {
        headers: {
          'Authorization': `Bearer ${currentToken.access_token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      await fetchSpreadsheetData(); // Refresh the data

      // Clear form but keep the next ID
      const nextId = (parseInt(formData.id) + 1).toString();
      setFormData({
        ...initialFormState,
        id: nextId
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred while submitting the form';
      setError(errorMessage);
      console.error('Error adding row:', error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Video Generation Management
        </Typography>
        
        {/* New description section */}
        <Paper 
          sx={{ 
            p: 3, 
            mb: 4, 
            bgcolor: 'rgba(25, 118, 210, 0.04)', 
            border: '1px solid rgba(25, 118, 210, 0.12)',
            borderRadius: 2
          }}
        >
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, color: 'primary.main' }}>
            How it works:
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            This video generation system is designed to work seamlessly with Make.com automation:
          </Typography>
          <Box component="ul" sx={{ m: 0, pl: 2 }}>
            <Typography component="li" variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              1. Add new rows with your image and prompt details below
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              2. Make.com automatically monitors for new entries
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary">
              3. Videos are generated automatically based on your inputs
            </Typography>
          </Box>
        </Paper>
      </Box>

      {/* Add New Row Section */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 3 }}>Add New Video Entry</Typography>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Add image upload section */}
            <Grid item xs={12}>
              <Paper
                sx={{
                  border: '2px dashed #9e9e9e',
                  borderRadius: 2,
                  p: 4,
                  textAlign: 'center',
                  cursor: 'pointer',
                  mb: 2,
                  background: 'rgba(0, 0, 0, 0.02)',
                  transition: 'all 0.3s ease',
                  '&:hover': { 
                    border: '2px dashed #1976d2',
                    background: 'rgba(25, 118, 210, 0.04)'
                  }
                }}
                component="label"
              >
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileUpload}
                  disabled={uploading}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <UploadIcon 
                    sx={{ 
                      fontSize: 64, 
                      color: uploading ? 'text.disabled' : 'primary.main', 
                      mb: 2,
                      animation: uploading ? 'pulse 1.5s infinite ease-in-out' : 'none',
                      '@keyframes pulse': {
                        '0%': { opacity: 0.6 },
                        '50%': { opacity: 1 },
                        '100%': { opacity: 0.6 }
                      }
                    }} 
                  />
                  <Typography variant="h6" color={uploading ? 'text.secondary' : 'primary'} gutterBottom>
                    {uploading ? 'Uploading...' : 'Upload Image'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {uploading 
                      ? 'Please wait while your image is being uploaded' 
                      : 'Click or drag and drop an image file here'}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                    Supports: JPG, PNG, GIF (Max 10MB)
                  </Typography>
                </Box>
              </Paper>
              
              {formData.image_url && (
                <Box 
                  sx={{ 
                    mt: 2, 
                    p: 2, 
                    border: '1px solid #e0e0e0', 
                    borderRadius: 1,
                    background: '#f9f9f9'
                  }}
                >
                  {/* Image Preview */}
                  <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Image Preview:
                    </Typography>
                    <Box 
                      sx={{ 
                        mt: 1,
                        p: 2,
                        border: '1px solid #e0e0e0',
                        borderRadius: 1,
                        background: '#fff',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <img 
                        src={formData.image_url} 
                        alt="Uploaded preview" 
                        style={{ 
                          maxWidth: '100%', 
                          maxHeight: '200px',
                          objectFit: 'contain',
                          borderRadius: '4px'
                        }} 
                      />
                    </Box>
                  </Box>

                  <Typography variant="subtitle2" gutterBottom>
                    Uploaded Image URL:
                  </Typography>
                  <Box 
                    sx={{ 
                      p: 1.5, 
                      background: '#fff', 
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      fontSize: '0.85rem',
                      fontFamily: 'monospace',
                      wordBreak: 'break-all',
                      color: 'text.secondary'
                    }}
                  >
                    {formData.image_url}
                  </Box>
                  
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ 
                      mt: 2,
                      bgcolor: '#1976d2', // Specific blue color
                      '&:hover': {
                        bgcolor: '#1565c0' // Darker blue on hover
                      }
                    }}
                    disabled={generating || !uploadedFile}
                    onClick={generatePrompts}
                    startIcon={
                      generating ? 
                      <CircularProgress size={20} color="inherit" /> : 
                      <AutoFixHighIcon />
                    }
                  >
                    {generating ? 'Generating...' : 'Auto-Generate Prompts'}
                  </Button>
                  
                  {generating && (
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                      AI is analyzing your image and creating prompts...
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>

            {/* Update form fields grid */}
            <Grid item xs={12} md={4}>
              <TextField
                required
                fullWidth
                label="ID"
                name="id"
                value={formData.id}
                onChange={handleInputChange}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                label="Production Status"
                name="production_status"
                value={formData.production_status}
                onChange={handleInputChange}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                label="Publish Status"
                name="publish_status"
                value={formData.publish_status}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                required
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label="Caption"
                name="caption"
                value={formData.caption}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                required
                fullWidth
                label="Prompt A"
                name="prompt_a"
                value={formData.prompt_a}
                onChange={handleInputChange}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                label="Action A"
                name="action_a"
                value={formData.action_a}
                onChange={handleInputChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Video A"
                name="video_a"
                value={formData.video_a}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={uploading}
            >
              Add Entry
            </Button>
          </Box>
        </form>
      </Paper>

      <Divider sx={{ my: 4 }} />

      {/* Display Spreadsheet Data Section */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 3 }}>Video Generation Entries</Typography>
        
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={spreadsheetData.map(row => ({
              ...row,
              id: row.id || Math.random().toString(36).substr(2, 9) // Ensure unique ID
            }))}
            columns={[
              { field: 'id', headerName: 'ID', width: 70 },
              { field: 'production_status', headerName: 'Production Status', width: 150 },
              { field: 'publish_status', headerName: 'Publish Status', width: 150 },
              { field: 'title', headerName: 'Title', width: 200 },
              { 
                field: 'caption', 
                headerName: 'Caption', 
                width: 250,
                renderCell: (params) => (
                  <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {params.value}
                  </div>
                )
              },
              { 
                field: 'image_url', 
                headerName: 'Image URL', 
                width: 200,
                renderCell: (params) => (
                  <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {params.value}
                  </div>
                )
              },
              { 
                field: 'prompt_a', 
                headerName: 'Prompt A', 
                width: 300,
                renderCell: (params) => (
                  <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {params.value}
                  </div>
                )
              },
              { 
                field: 'action_a', 
                headerName: 'Action A', 
                width: 200,
                renderCell: (params) => (
                  <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {params.value}
                  </div>
                )
              },
              { field: 'video_a', headerName: 'Video A', width: 200 }
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-cell': { 
                lineHeight: 'normal',
                padding: '8px',
                alignItems: 'flex-start'
              }
            }}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default VideoGeneration; 