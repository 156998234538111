import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useInactivityReset } from '../../hooks/useInactivityReset';

const InactivityHandler = ({ children }) => {
    const [showWarning, setShowWarning] = useState(false);
    const [hasRunningPods, setHasRunningPods] = useState(false);
    const RUNPOD_API_URL = process.env.REACT_APP_BACKEND_URL;

    useInactivityReset(1800000); // 30 minutes for logout

    useEffect(() => {
        const checkRunningPods = async () => {
            try {
                const authToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
                const response = await fetch(`${RUNPOD_API_URL}/pods`, {
                    headers: {
                        'Authorization': `Bearer ${authToken?.access_token}`,
                        'Content-Type': 'application/json',
                    }
                });
                const pods = await response.json();
                setHasRunningPods(pods.some(pod => pod.status === 'RUNNING'));
            } catch (error) {
                console.error('Error checking pods:', error);
            }
        };

        const warningTimeout = setTimeout(() => {
            checkRunningPods().then(() => {
                setShowWarning(true);
            });
        }, 1500000); // Show warning after 25 seconds

        return () => clearTimeout(warningTimeout);
    }, []);

    return (
        <>
            {children}
            <Snackbar
                open={showWarning}
                autoHideDuration={30000}
                onClose={() => setShowWarning(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity="warning"
                    onClose={() => setShowWarning(false)}
                    sx={{ width: '100%' }}
                >
                    {hasRunningPods
                        ? "Your session will expire in 5 minutes. Active pods will be stopped automatically."
                        : "Your session will expire in 5 minutes due to inactivity."}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InactivityHandler; 