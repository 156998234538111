import { Container, Grid, Paper, Skeleton, Typography, IconButton, Snackbar, Alert, Box, Chip, Divider, Stack, Tooltip, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PodModal from './component/PodModal';
import DeleteIcon from '@mui/icons-material/Delete';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { createClient } from '@supabase/supabase-js'
import { Settings, HelpOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const authToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
const accessToken = authToken?.access_token;
const RUNPOD_API_URL = process.env.REACT_APP_BACKEND_URL;
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const PodSetup = () => {
    const [pods, setPods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' // 'error', 'warning', 'info', 'success'
    });
    const navigate = useNavigate();

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity,
        });
    };

    useEffect(() => {
        if (accessToken) {
            fetchPods();
        }
    }, [accessToken]);

    const fetchPods = async () => {
        if (!accessToken) {
            showSnackbar('No access token available', 'error');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(`${RUNPOD_API_URL}/pods`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            setPods(Array.isArray(response.data) ? response.data : []);
            setError(null);
            showSnackbar('Pods fetched successfully');
        } catch (err) {
            setError('Failed to fetch pods');
            console.error(err);
            setPods([]);
            showSnackbar('Failed to fetch pods', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handlePodDelete = async (podId) => {
        if (!podId) {
            showSnackbar('Pod ID is required', 'error');
            return;
        }
        try {
            const response = await axios.delete(`${RUNPOD_API_URL}/pods/delete?pod_id=${podId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            await supabase.from('pod_activities').insert([{
                user_id: authToken.user.id,
                pod_id: podId,
                action_type: 'DELETE',
                pod_status: 'DELETED'
            }]);

            fetchPods();
            showSnackbar('Pod deleted successfully');
        } catch (err) {
            console.error(err);
            showSnackbar('Failed to delete pod', 'error');
        }
    };

    const handleCreatePod = async (podConfig) => {
        try {
            // First create user profile if it doesn't exist
            const { error: profileError } = await supabase
                .from('user_profiles')
                .upsert({
                    id: authToken.user.id,
                    full_name: authToken.user.user_metadata.full_name,
                    email: authToken.user.email,
                    total_pod_starts: 0,
                    total_pod_stops: 0
                });

            if (profileError) throw profileError;

            const response = await axios.post(`${RUNPOD_API_URL}/pods/create`, podConfig, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            
            await supabase.from('pod_activities').insert([{
                user_id: authToken.user.id,
                pod_id: response.data.id,
                pod_name: podConfig.name,
                action_type: 'CREATE',
                pod_type: podConfig.runtime,
                gpu_count: podConfig.gpuCount || 0
            }]);

            fetchPods();
            showSnackbar('Pod created successfully');
        } catch (error) {
            console.error('Error creating pod:', error);
            showSnackbar('Failed to create pod', 'error');
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'RUNNING':
                return 'success';
            case 'EXITED':
                return 'error';
            case 'STARTING':
                return 'warning';
            default:
                return 'default';
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

            <PodModal fetchPods={fetchPods} showSnackbar={showSnackbar} />

            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {loading ? (
                        <Box sx={{ width: '100%' }}>
                            <Skeleton variant="rectangular" height={100} sx={{ mb: 2, borderRadius: 2 }} />
                            <Skeleton variant="rectangular" height={100} sx={{ mb: 2, borderRadius: 2 }} />
                        </Box>
                    ) : pods?.length > 0 ? (
                        pods.map((pod) => (
                            <Paper
                                key={pod.id}
                                elevation={0}
                                sx={{
                                    p: 4,
                                    mb: 3,
                                    width: '100%',
                                    borderRadius: 3,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 4px 24px rgba(0,0,0,0.06)',
                                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 6px 28px rgba(0,0,0,0.1)',
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            {pod.name}
                                            <Chip
                                                label={pod.status}
                                                color={getStatusColor(pod.status)}
                                                size="small"
                                                sx={{ ml: 2 }}
                                            />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            ID: {pod.id}
                                        </Typography>
                                    </Box>
                                    <IconButton onClick={() => handlePodDelete(pod.id)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>

                                <Divider sx={{ my: 2 }} />

                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={2}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <StorageIcon color="primary" />
                                                <Typography variant="body2">
                                                    Storage: {pod.container_disk_in_gb}GB (Container) / {pod.volume_in_gb}GB (Volume)
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <MemoryIcon color="primary" />
                                                <Typography variant="body2">
                                                    Memory: {pod.memory_in_gb}GB RAM / {pod.vcpu_count} vCPUs
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <AttachMoneyIcon color="primary" />
                                                <Typography variant="body2">
                                                    Cost: ${pod.cost_per_hr}/hour
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={2}>
                                            <Typography variant="body2">
                                                <strong>Pod Type:</strong> {pod.pod_type}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Desired Status:</strong> {pod.desired_status}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Machine ID:</strong> {pod.machine_id}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Runtime:</strong> {pod.runtime || 'None'}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Mount Path:</strong> {pod.volume_mount_path}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Ports:</strong> {pod.ports}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Environment:</strong>
                                                <Box component="pre" sx={{ 
                                                    mt: 1,
                                                    p: 1,
                                                    bgcolor: 'grey.100',
                                                    borderRadius: 1,
                                                    fontSize: '0.875rem',
                                                    overflow: 'auto'
                                                }}>
                                                    {pod.env?.join('\n')}
                                                </Box>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 2 }} />

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Uptime: {pod.uptime_seconds} seconds
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Last Status Change: {pod.last_status_change}
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 2 }} />

                                <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 600 }}>
                                Want to start generating images?
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                You'll need to create and manage a pod first. Our help guide will walk you through the process.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/dashboard')}
                                    startIcon={<Settings />}
                                    size="large"
                                >
                                    Pod Management
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => navigate('/help')}
                                    startIcon={<HelpOutline />}
                                    size="large"
                                >
                                    Help Guide
                                </Button>
                            </Box>
                            </Paper>

                        ))
                    ) : (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 6,
                                borderRadius: 3,
                                bgcolor: 'background.paper',
                                boxShadow: '0 4px 24px rgba(0,0,0,0.06)',
                                textAlign: 'center'
                            }}
                        >
                            <Box sx={{ maxWidth: 500, mx: 'auto' }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: 'primary.main' }}>
                                    Ready to Start Your Journey?
                                </Typography>
                                <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                    Create your first pod to begin generating amazing images. Our help guide will walk you through the process step by step.
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/dashboard')}
                                        startIcon={<Settings />}
                                        size="large"
                                        sx={{ 
                                            px: 4,
                                            boxShadow: '0 2px 8px rgba(37, 99, 235, 0.2)',
                                            '&:hover': {
                                                boxShadow: '0 4px 12px rgba(37, 99, 235, 0.3)',
                                            }
                                        }}
                                    >
                                        Pod Management
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => navigate('/help')}
                                        startIcon={<HelpOutline />}
                                        size="large"
                                        sx={{ px: 4 }}
                                    >
                                        Help Guide
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                    )}
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default PodSetup